import { InputNumber, message, Modal } from 'antd';
import type { BaseData } from 'egenie-utils';
import { ImgFormatter, MainSubStructure, MainSubStructureModel, request } from 'egenie-utils';
import { action, observable } from 'mobx';
import { Observer, observer } from 'mobx-react';
import React from 'react';
import type { StockMainItem } from './types';
import { formatNumber } from 'egenie-common';
import type { Store } from './store';

interface DataItem {
  color?: string;
  id?: string;
  number?: string;
  pic?: string;
  productNo?: string;
  size?: string;
  _count?: number;
}

export class CreatePaymentModalModel {
  constructor(private parent: Store) {
  }

  @observable.ref public mainItem: StockMainItem = {};

  @observable public visible = false;

  @observable public isLoading = false;

  public mainSubStructureModel = new MainSubStructureModel({
    grid: {
      columns: [
        {
          key: 'pic',
          name: '图片',
          width: 100,
          formatter: ({ row }) => <ImgFormatter value={row.pic}/>,
        },
        {
          key: 'productNo',
          name: '商品编码',
        },
        {
          key: 'color',
          name: '颜色',
          width: 80,
        },
        {
          key: 'size',
          name: '尺寸',
          width: 80,
        },
        {
          key: 'number',
          name: '入库量',
          width: 80,
        },
        {
          key: '_count',
          name: '生成量',
          width: 120,
          formatter: ({
            rowIdx,
            row,
          }: { rowIdx: number; row: DataItem; }) => {
            return (
              <Observer>
                {
                  () => (
                    <InputNumber
                      max={formatNumber(row.number)}
                      min={0}
                      onChange={(value) => this.mainSubStructureModel.gridModel.rows[rowIdx]._count = formatNumber(value)}
                      onClick={(event) => event.stopPropagation()}
                      placeholder="请输入生成量"
                      size="small"
                      style={{ width: '100%' }}
                      value={this.mainSubStructureModel.gridModel.rows[rowIdx]._count}
                    />
                  )
                }
              </Observer>
            );
          },
        },
      ].map((v) => ({
        resizable: true,
        sortable: false,
        ...v,
      })),
      rows: [],
      primaryKeyField: 'id',
      showPager: false,
      showCheckBox: true,
      showSelectedTotal: false,
    },
    api: {
      onQuery: () => {
        return request<BaseData<DataItem[]>>({
          url: '/api/cloud/wms/rest/bill/other/detail/all',
          params: { wmsOtherStockId: this.mainItem?.id },
        })
          .then((info) => ({
            ...info,
            data: {
              ...info.data,
              list: (info.data || []).map((item) => ({
                ...item,
                _count: formatNumber(item.number),
              })),
            },
          }));
      },
    },
    hiddenSubTable: true,
  });

  @action public handleCancel = () => {
    this.visible = false;
    this.mainItem = {};
    this.mainSubStructureModel.gridModel.resetAll();
  };

  @action public handleOk = () => {
    const data: DataItem[] = this.mainSubStructureModel.gridModel.selectRows.filter((item) => item._count > 0 && item._count <= item.number);
    if (data.length === 0) {
      const error = '请选择数据并且输入数量';
      message.warning({
        key: error,
        content: error,
      });
      return;
    }

    this.isLoading = true;
    request({
      url: '/api/cloud/wms/rest/bill/other/mall/generate/bill',
      method: 'post',
      data: data.map((item) => ({
        otherStockDetailId: item.id,
        generateNum: item._count,
      })),
    })
      .then(() => {
        message.success('生成成功');
        this.handleCancel();
        this.parent.gridModel.onRefresh();
      })
      .finally(() => this.isLoading = false);
  };

  @action public handleOpen = (purchaseSkuDetailItem: StockMainItem) => {
    this.visible = true;
    this.mainItem = purchaseSkuDetailItem || {};
    this.mainSubStructureModel.gridModel.resetAll();
    this.mainSubStructureModel.gridModel.onQuery();
  };
}

@observer
export class CreatePaymentModal extends React.Component<{ createPaymentModalModel: CreatePaymentModalModel; }> {
  render() {
    const {
      createPaymentModalModel: {
        handleCancel,
        visible,
        mainSubStructureModel,
        isLoading,
        handleOk,
      },
    } = this.props;
    return (
      <Modal
        centered
        confirmLoading={isLoading}
        onCancel={handleCancel}
        onOk={handleOk}
        title="生成应付单"
        visible={visible}
        width={1000}
      >
        <div style={{ height: 600 }}>
          <MainSubStructure store={mainSubStructureModel}/>
        </div>
      </Modal>
    );
  }
}

