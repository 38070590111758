import { Button, InputNumber, message } from 'antd';
import type { PaginationData } from 'egenie-common';
import { formatNumber, request } from 'egenie-common';
import { ImgFormatter, MainSubStructureModel } from 'egenie-utils';
import { action, observable, toJS } from 'mobx';
import { Observer } from 'mobx-react';
import { nanoid } from 'nanoid';
import React from 'react';
import type { StockDetailItem } from '../types';
import { skuCondition } from '../utils';

export class Store {
  constructor(
    callback: (rows: StockDetailItem[]) => void,
    extraParams: Store['extraParams']
  ) {
    this.callback = callback;
    this.extraParams = extraParams;

    this.goodsStore = new MainSubStructureModel({
      grid: {
        columns: [
          {
            width: 100,
            key: '_id',
            frozen: true,
            name: '操作',
            formatter: ({ row }) => {
              return (
                <Button
                  onClick={() => this.handleAdd([row])}
                  type="link"
                >
                  添加
                </Button>
              );
            },
          },
          {
            key: 'skuNo',
            name: 'SKU编码',
            width: 200,
          },
          {
            key: 'productNo',
            name: '商品编码',
            width: 200,
          },
          {
            name: '商品名称',
            key: 'productName',
            width: 150,
          },
          {
            key: 'pic',
            name: '图片',
            width: 50,
            formatter: ({ row }) => <ImgFormatter value={row.pic}/>,
          },
          {
            key: 'color',
            name: '颜色',
            width: 100,
          },
          {
            key: 'size',
            name: '尺码',
            width: 100,
          },
          {
            name: '规格',
            key: 'spec',
            width: 150,
          },
          {
            name: '货主',
            key: 'ownerName',
            width: 150,
          },
          {
            name: '供应商',
            key: 'vendorName',
            width: 150,
          },
          {
            name: '采购员',
            key: 'employeeName',
            width: 150,
          },
          {
            name: '成本价',
            key: 'costPrice',
            width: 80,
          },
          {
            name: '采购价',
            key: 'purchasePrice',
            width: 80,
          },
          {
            name: '库位',
            key: 'warehouseBinNo',
            width: 150,
          },
          {
            name: '锁定数量',
            key: 'lockNumber',
            width: 80,
          },
        ].filter(Boolean)
          .map((v) => ({
            resizable: true,
            sortable: false,
            ...v,
          })),
        rows: [],
        primaryKeyField: '_id',
        sortByLocal: false,
        showPager: true,
        setColumnsDisplay: true,
        showRefresh: false,
        gridIdForColumnConfig: '/egenie-cloud-wms/stockIn/index/3',
      },
      api: {
        onQuery: (params) => {
          if (this.selectValue && this.inputValue && this.inputValue.trim().length) {
            const {
              filterParams = {},
              ...rest
            } = params;
            return request<PaginationData<StockDetailItem>>({
              url: '/api/cloud/baseinfo/rest/sku/findByConditions',
              method: 'POST',
              data: {
                ...filterParams,
                ...rest,
                ...this.extraParams,
                batch: true,
                stock: false,
                key: this.selectValue,
                value: this.inputValue.trim(),
                enable: this.enable,
                [Object.values(skuCondition)
                  .find((item) => item.value === this.selectValue)?.paramsKey]: this.inputValue.trim(),
              },
            })
              .then((info) => ({
                ...info,
                data: {
                  ...info?.data,
                  list: (info?.data?.list || []).map((item) => ({
                    ...item,
                    _id: nanoid(),
                  })),
                },
              }));
          } else {
            message.error('请输入查询条件');
            return Promise.reject();
          }
        },
      },
      hiddenSubTable: true,
    });
    this.tmpStore = new MainSubStructureModel({
      grid: {
        columns: [
          {
            width: 100,
            key: '_id',
            frozen: true,
            name: '操作',
            formatter: ({ row }) => {
              return (
                <Button
                  onClick={() => this.handleDelete([row])}
                  type="link"
                >
                  删除
                </Button>
              );
            },
          },
          {
            key: 'skuNo',
            name: 'SKU编码',
            width: 200,
          },
          {
            key: 'productNo',
            name: '商品编码',
            width: 200,
          },
          {
            name: '商品名称',
            key: 'productName',
            width: 150,
          },
          {
            name: '数量',
            key: 'number',
            width: 120,
            formatter: ({ rowIdx }) => {
              return (
                <Observer>
                  {
                    () => (
                      <InputNumber
                        max={99999}
                        min={1}
                        onChange={(value) => this.tmpStore.gridModel.rows[rowIdx].number = formatNumber(value)}
                        onClick={(event) => event.stopPropagation()}
                        size="small"
                        value={this.tmpStore.gridModel.rows[rowIdx].number}
                      />
                    )
                  }
                </Observer>
              );
            },
          },
          {
            key: 'pic',
            name: '图片',
            formatter: ({ row }) => <ImgFormatter value={row.pic}/>,
            width: 50,
          },
          {
            key: 'color',
            name: '颜色',
            width: 100,
          },
          {
            key: 'size',
            name: '尺码',
            width: 100,
          },
          {
            name: '规格',
            key: 'spec',
            width: 150,
          },
          {
            name: '货主',
            key: 'ownerName',
            width: 150,
          },
          {
            name: '供应商',
            key: 'vendorName',
            width: 150,
          },
          {
            name: '采购员',
            key: 'employeeName',
            width: 150,
          },
          {
            name: '成本价',
            key: 'costPrice',
            width: 80,
          },
          {
            name: '采购价',
            key: 'purchasePrice',
            width: 80,
          },
          {
            name: '库位',
            key: 'warehouseBinNo',
            width: 150,
          },
          {
            name: '锁定数量',
            key: 'lockNumber',
            width: 80,
          },
        ].map((v) => ({
          resizable: true,
          sortable: false,
          ...v,
        })),
        rows: [],
        primaryKeyField: '_id',
        sortByLocal: false,
        showCheckBox: true,
        showPager: true,
        setColumnsDisplay: true,
        showRefresh: false,
        gridIdForColumnConfig: '/egenie-cloud-wms/stockIn/index/4',
      },
      api: { onQuery: () => Promise.reject() },
      hiddenSubTable: true,
    });
  }

  private extraParams: {[key: string]: string | number; } = {};

  @observable public enable = '1';

  @action public handleEnable = (enable: string): void => {
    this.enable = enable;
  };

  @action public handleSave = () => {
    if (this.tmpStore.gridModel.rows.length) {
      if (typeof this.callback === 'function') {
        this.callback(toJS(this.tmpStore.gridModel.rows));
      }
    } else {
      const error = '请至少选择一行';
      message.error({
        key: error,
        content: error,
      });
    }
  };

  public callback?: (rows: StockDetailItem[]) => void;

  public goodsStore: MainSubStructureModel;

  public tmpStore: MainSubStructureModel;

  @observable public selectValue = skuCondition.skuNo.value;

  @action public handleSelectValueChange = (selectValue: string) => {
    this.selectValue = selectValue;
  };

  @observable public inputValue = '';

  @action public handleInputValueChange = (inputValue: string) => {
    this.inputValue = inputValue;
  };

  @action
  public handleAdd = (rows: StockDetailItem[]) => {
    rows.forEach((item) => {
      const equalItem: StockDetailItem | null = this.tmpStore.gridModel.rows.find((val) => val.id === item.id);
      if (equalItem) {
        equalItem.number = formatNumber(equalItem.number) + 1;
      } else {
        this.tmpStore.gridModel.rows.push({
          ...item,
          number: 1,
        });
      }
    });
  };

  @action
  public handleDelete = (rows: StockDetailItem[]) => {
    this.tmpStore.gridModel.rows = this.tmpStore.gridModel.rows.filter((item) => {
      return rows.findIndex((val) => val._id === item._id) === -1;
    });
  };
}
