import type { DictData, PaginationData } from 'egenie-common';
import { request, objToDict } from 'egenie-common';
import type { FilterConfigData } from 'egenie-utils';
import { MainSubStructureModel, Programme, ExportStore, formatFilterConfigData } from 'egenie-utils';
import { action, observable } from 'mobx';
import React from 'react';
import { formatWarehouseChoice, getAllVendor, getOwner, getWarehouse, logSubGrid } from '../../utils';
import { mainTableButton } from './mainTableButton';
import { mainTableColumns } from './mainTableColumns';
import { stockInDetailGrid } from './stockInDetailGrid';
import { stockInFilterItems } from './stockInFilterItems';
import type { StockMainItem } from './types';
import { UniqueCodeDetailModel } from './uniqueCodeDetail';
import { CreatePaymentModalModel } from './createPaymentModal';
import { BOOLMap } from './utils';

export class Store {
  constructor() {
    // 仓库
    getWarehouse()
      .then((info) => {
        this.programme.filterItems.updateFilterItem([
          {
            field: 'warehouseId',
            data: info,
            value: formatWarehouseChoice(info, 'warehouseId'),
          },
        ]);
        this.getDataAfterWarehouse(formatWarehouseChoice(info, 'warehouseId'));
      });

    // 单据状态
    request<DictData>({ url: '/api/cloud/wms/rest/bill/other/approve/state' })
      .then((info) => this.programme.filterItems.addDict({ approveState: objToDict(info) }));

    // 单据类型
    request<DictData>({ url: '/api/cloud/wms/rest/bill/other/in/stock/order/type' })
      .then((info) => this.programme.filterItems.addDict({ otherStockOrderType: objToDict(info) }));

    // 供应商
    getAllVendor()
      .then((info) => this.programme.filterItems.addDict({
        vendorId: info,
        vendorShopId: info,
      }));

    // 创建人
    request<FilterConfigData>({
      url: '/api/cloud/baseinfo/rest/filterSet2/getConfig2',
      params: {
        itemList: 'user',
        module: '',
      },
    })
      .then((info) => {
        const list = formatFilterConfigData(info, { user: 'creator' });
        this.programme.filterItems.addDict(list.reduce((prev, current) => ({
          ...prev,
          [current.field]: current.data,
        }), {}));
      });
  }

  public getDataAfterWarehouse = (warehouseId: string): void => {
    // 货主
    getOwner({ warehouseId })
      .then((info) => this.programme.filterItems.addDict({ ownerId: info }));
  };

  public uniqueCodeDetailModel: UniqueCodeDetailModel = new UniqueCodeDetailModel();

  public createPaymentModalModel: CreatePaymentModalModel = new CreatePaymentModalModel(this);

  public gridModel = new MainSubStructureModel({
    buttons: mainTableButton(this),
    pageId: '60006',
    grid: {
      // @ts-ignore
      columns: mainTableColumns(this),
      rows: [],
      primaryKeyField: 'id',
      sortByLocal: false,
      showCheckBox: true,
      showEmpty: true,
      pageSize: 50,
      setColumnsDisplay: true,
      gridIdForColumnConfig: '/egenie-cloud-wms/stockIn/index/1',
      sumColumns: [
        'totalNumber',
        'totalPrice',
      ],
    },

    // 主表查询api
    api: {
      onQuery: (params) => {
        const {
          filterParams = {},
          ...rest
        } = params;
        const { pledge } = filterParams;
    
        return request<PaginationData<StockMainItem>>({
          url: '/api/cloud/wms/rest/bill/other/page',
          data: {
            ...rest,
            orderType: '1',
            'order_type-4-1': '0, 2',
            ...filterParams,
            pledge: BOOLMap[pledge],
          },
          method: 'POST',
          headers: { warehouseId: filterParams?.warehouseId || '' },
        });
      },
    },
    subTables: {
      activeTab: 'detail',
      tabsFlag: {
        inited: {
          detail: true,
          log: false,
        },
        searched: {},
      },
      list: [
        // @ts-ignore
        stockInDetailGrid(this),
        logSubGrid(230000, {}, 'warehouseId'),
      ],
    },
  });

  public programme = new Programme({
    gridModel: this.gridModel,
    filterItems: stockInFilterItems(this),
    moduleName: 'egenieCloudWmsStockInIndex',
  });

  @observable public showAddModal = false;

  @action public openAddModal = () => {
    this.showAddModal = true;
  };

  @action public closeAddModal = () => {
    this.showAddModal = false;
  };

  @action public addModalCallback = () => {
    this.closeAddModal();
    this.programme.gridModel.onRefresh();
  };

  @observable public showEditModal = false;

  @observable public mainItem: StockMainItem = null;

  @action public openEditModal = (mainItem: StockMainItem) => {
    this.mainItem = mainItem;
    this.showEditModal = true;
  };

  @action public closeEditModal = () => {
    this.mainItem = null;
    this.showEditModal = false;
  };

  @action public editModalCallback = () => {
    this.closeEditModal();
    this.programme.gridModel.onRefresh();
  };

  public exportStore: ExportStore = new ExportStore({ parent: null });
}
