import { Modal } from 'antd';
import type { PaginationData } from 'egenie-utils';
import { MainSubStructure, MainSubStructureModel, TimeStampFormatter, request } from 'egenie-utils';
import { action, observable, toJS } from 'mobx';
import { observer } from 'mobx-react';
import React from 'react';
import type { StockDetailItem } from './types';

let id = 0;

export class UniqueCodeDetailModel {
  @observable.ref public purchaseSkuDetailItem: StockDetailItem = {};

  @observable public visible = false;

  public mainSubStructureModel = new MainSubStructureModel({
    grid: {
      columns: [
        {
          key: 'skuNo',
          name: 'sku编码',
        },
        {
          key: 'uniqueCode',
          name: '唯一码',
        },
        {
          key: 'uniqueCodeStatusName',
          name: '唯一码状态',
          width: 100,
        },
        {
          key: 'printTime',
          name: '创建时间',
          width: 150,
          formatter: ({ row }) => <TimeStampFormatter value={row.printTime}/>,
        },
      ].map((v) => ({
        resizable: true,
        sortable: false,
        ...v,
      })),
      rows: [],
      primaryKeyField: '_id',
      showCheckBox: false,
      showReset: false,
      showSelectedTotal: false,
    },
    api: {
      onQuery: (params) => {
        const {
          filterParams,
          ...rest
        } = params;
        return request<PaginationData<any>>({
          method: 'POST',
          url: '/api/cloud/wms/rest/bill/other/sn/detail/page',
          data: {
            ...rest,
            wmsOtherStockOrderId: this.purchaseSkuDetailItem.wmsOtherStockOrderId,
            wmsOtherStockOrderDetailId: this.purchaseSkuDetailItem.id,
          },
          headers: { warehouseId: this.purchaseSkuDetailItem?.warehouseId || '' },
        })
          .then((info) => ({
            ...info,
            data: {
              ...info?.data,
              list: (info.data?.list || []).map((item) => ({
                ...item,
                _id: id++,
              })),
            },
          }));
      },
    },
    hiddenSubTable: true,
  });

  @action public handleCancel = () => {
    this.visible = false;
    this.purchaseSkuDetailItem = {};
    this.mainSubStructureModel.gridModel.resetAll();
  };

  @action public handleOpen = (purchaseSkuDetailItem: StockDetailItem) => {
    this.visible = true;
    this.purchaseSkuDetailItem = toJS(purchaseSkuDetailItem);
    this.mainSubStructureModel.gridModel.resetAll();
    this.mainSubStructureModel.gridModel.onQuery();
  };
}

@observer
export class UniqueCodeDetailModal extends React.Component<{ uniqueCodeDetailModel: UniqueCodeDetailModel; }> {
  render() {
    const {
      uniqueCodeDetailModel: {
        handleCancel,
        visible,
        mainSubStructureModel,
      },
    } = this.props;
    return (
      <Modal
        centered
        footer={null}
        onCancel={handleCancel}
        title="唯一码详情"
        visible={visible}
        width={900}
      >
        <div style={{ height: 600 }}>
          <MainSubStructure store={mainSubStructureModel}/>
        </div>
      </Modal>
    );
  }
}
