import { message, Modal } from 'antd';
import type { BaseData } from 'egenie-common';
import { getStaticResourceUrl, request, getCustomPrintParam, printHelper, renderModal, destroyAllModal } from 'egenie-common';
import { values } from 'mobx';
import React from 'react';
import { ImportFile } from '../../utils';
import type { Store } from './store';
import type { StockMainItem, StockDetailItem } from './types';

function handleExport(context: Store, fileName: string, otherParams = {}) {
  const ids = values(context.programme.gridModel.gridModel.selectedIds)
    .join(',');
  const exportType = 'cloud_wms_in_stock';
  const filterParams = context.programme.filterItems.params;
  const queryParam = {
    ...filterParams,
    orderType: '1',
    'order_type-4-1': '0, 2',
  };
  const queryParamShow = context.programme.filterItems.translateParams.join(' ');

  if (ids) {
    context.exportStore.onShow(fileName, exportType, ids, queryParam, queryParamShow, otherParams);
  } else {
    Modal.confirm({
      title: '提示',
      content: '未选择数据将导出全部数据?',
      onOk: () => {
        context.exportStore.onShow(fileName, exportType, '', queryParam, queryParamShow, otherParams);
      },
    });
  }
}

export function mainTableButton(context: Store) {
  return [
    {
      permissionId: '1',
      text: '新建',
      icon: 'icon-add',
      handleClick: () => {
        context.openAddModal();
      },
    },
    {
      permissionId: '21',
      text: '审核',
      icon: 'icon-audit',
      handleClick: () => {
        const selectRows: StockMainItem[] = context.gridModel.gridModel.selectRows;
        if (!selectRows.length) {
          const error = '请选择数据';
          message.warning({
            key: error,
            content: error,
          });
          return;
        }

        if (selectRows.some((item) => item.approveState == 2)) {
          const error = '存在已经审核的数据';
          message.warning({
            key: error,
            content: error,
          });
          return;
        }

        Modal.confirm({
          content: '确认审核吗?',
          onOk: () => request({
            url: '/api/cloud/wms/rest/bill/other/approve',
            method: 'post',
            data: {
              otherStockOrderIds: selectRows.map((item) => item.id)
                .join(','),
            },
          })
            .then(() => {
              context.programme.gridModel.onRefresh();
              message.success('审核成功');
            }),
        });
      },
    },
    {
      permissionId: '45',
      text: '删除',
      icon: 'icon-delete',
      handleClick: () => {
        const selectRows: StockMainItem[] = context.gridModel.gridModel.selectRows;
        if (!selectRows.length) {
          const error = '请选择数据';
          message.warning({
            key: error,
            content: error,
          });
          return;
        }

        if (!selectRows.every((item) => item.approveState == 1)) {
          const error = '只能删除未审核的数据';
          message.warning({
            key: error,
            content: error,
          });
          return;
        }

        Modal.confirm({
          content: '确认删除吗?',
          onOk: () => request({
            url: '/api/cloud/wms/rest/bill/other/delete',
            method: 'post',
            data: {
              otherStockOrderIds: selectRows.map((item) => item.id)
                .join(','),
            },
          })
            .then(() => {
              context.programme.gridModel.onQuery();
              message.success('删除成功');
            }),
        });
      },
    },
    {
      permissionId: '3019',
      text: '打印入库单',
      icon: 'icon-print',
      handleClick: async() => {
        const selectRows: StockMainItem[] = context.gridModel.gridModel.selectRows;
        const ids = selectRows.map((item) => item.id)
          .join(',');
        if (selectRows.length !== 1) {
          const error = '请选择一条主表数据';
          message.warning({
            key: error,
            content: error,
          });
          return;
        }

        const customPrintParam = await getCustomPrintParam('17');
        const printData = await request<BaseData<{ userDataList: any[]; tempData: any; }>>({
          url: '/api/cloud/print/wms/bill/other/temp',
          method: 'post',
          data: {
            tempId: customPrintParam.templateId,
            tempType: customPrintParam.tempType,
            id: ids,
          },
        });
        printHelper.toggleToLodop();
        await printHelper.print({
          contents: printData?.data?.userDataList || [],
          templateData: printData.data.tempData || {},
          preview: customPrintParam.preview,
        });
      },
    },
    {
      permissionId: '2034',
      text: '打印唯一码',
      icon: 'icon-barcode',
      handleClick: async() => {
        const selectMainRows: StockMainItem[] = context.gridModel.gridModel.selectRows;
        let selectSubRows: StockDetailItem[] = [];
        if (context.gridModel.subTablesModel.activeTab === 'detail') {
          const subModel = context.gridModel.subTablesModel.listModel.find((item) => item.tab.value === 'detail');

          selectSubRows = subModel.gridModel.selectRows || [];
        }

        if ((selectMainRows.length === 0 && selectSubRows.length) || selectMainRows.length === 1) {
          const printData = await request<BaseData<{ userDataList: any[]; tempData: any; }>>({
            url: '/api/cloud/print/wms/bill/other/get/unique/code/print/data',
            method: 'post',
            data: {
              tempType: '27',
              tempId: 0,
              wmsOrderEnterId: selectMainRows.map((item) => item.id)
                .join(',') || context.gridModel.gridModel?.cursorRow?.id,
              wmsDetailIds: selectSubRows.map((item) => item.id)
                .join(','),
            },
          });
          printHelper.toggleToLodop();
          await printHelper.print({
            contents: printData?.data?.userDataList || [],
            preview: false,
            templateData: printData?.data?.tempData || {},
          });
        } else {
          const error = '请选择一条主表或者子表数据';
          message.warning({
            key: error,
            content: error,
          });
        }
      },
    },
    {
      permissionId: '114',
      text: '打印合格证',
      icon: 'icon-barcode',
      handleClick: async() => {
        const selectMainRows: StockMainItem[] = context.gridModel.gridModel.selectRows;
        const ids = selectMainRows.map((item) => item.id)
          .join(',') || context.gridModel.gridModel?.cursorRow?.id;
        let selectSubRows: StockDetailItem[] = [];
        if (context.gridModel.subTablesModel.activeTab === 'detail') {
          const subModel = context.gridModel.subTablesModel.listModel.find((item) => item.tab.value === 'detail');

          selectSubRows = subModel.gridModel.selectRows || [];
        }

        if ((selectMainRows.length === 0 && selectSubRows.length) || selectMainRows.length === 1) {
          const printParam = await getCustomPrintParam('7', {
            customUrl: '/api/cloud/wms/rest/print/template/list',
            customParams: {
              ids,
              detailIds: selectSubRows.map((item) => item.id)
                .join(','),
              idType: 2,
            },
          });

          const printData = await request<BaseData<{ userData: any[]; tempData: any; }>>({
            method: 'post',
            url: '/api/cloud/wms/rest/print/template/sku/info',
            data: {
              ids,
              detailIds: selectSubRows.map((item) => item.id)
                .join(','),
              tempType: printParam.tempType,
              idType: 2,
              tempId: printParam.templateId,
            },
          });

          printHelper.toggleToRookie();
          await printHelper.print({
            preview: printParam.preview,
            printer: printParam.printer,
            templateData: printData?.data?.tempData,
            contents: printData?.data?.userData,
          });
        } else {
          const error = '请选择一条主表或者子表数据';
          message.warning({
            key: error,
            content: error,
          });
        }
      },
    },
    {
      permissionId: '294',
      text: '生成应付单',
      icon: 'icon-audit',
      handleClick: () => {
        const selectRows: StockMainItem[] = context.gridModel.gridModel.selectRows;
        if (selectRows.length === 0) {
          const error = '请至少选择一条数据';
          message.warning({
            key: error,
            content: error,
          });
          return;
        }

        if (!selectRows.every((item) => item.approveState == 2)) {
          const error = '存在未审核的数据';
          message.warning({
            key: error,
            content: error,
          });
          return;
        }

        if (!selectRows.every((item) => item.billStatus == 0 || item.billStatus == 1)) {
          const error = '当前选择的单据已生成过应付单,不可重复生成';
          message.warning({
            key: error,
            content: error,
          });
          return;
        }

        if (selectRows.some((item) => item.otherStockOrderType == 32)) {
          const error = '退货入库单不可生成应付单';
          message.warning({
            key: error,
            content: error,
          });
          return;
        }

        Modal.confirm({
          content: '确认生成吗?',
          onOk: () => request({
            url: '/api/cloud/wms/rest/bill/other/generateBill',
            method: 'post',
            params: {
              otherStockOrderIds: selectRows.map((item) => item.id)
                .join(','),
              type: 1,
            },
          })
            .then(() => {
              context.programme.gridModel.onQuery();
              message.success('生成成功');
            }),
        });
      },
    },
    {
      text: '导出',
      icon: 'icon-export',
      handleClick: () => handleExport(context, '入库单'),
    },
    {
      permissionId: '6',
      text: '唯一码导出',
      icon: 'icon-export',
      handleClick: () => handleExport(context, '入库单-唯一码', { uniqueCode: 'uniqueCode' }),
    },
    {
      permissionId: '5',
      text: '导入',
      icon: 'icon-import',
      handleClick: () => {
        renderModal(
          <ImportFile
            callback={((params) => {
              const fd = new FormData();
              for (const key in params) {
                if (Object.prototype.hasOwnProperty.call(params, key)) {
                  fd.append(key, params[key]);
                }
              }

              return request({
                url: '/api/cloud/wms/rest/excelImport/wmsOtherInStockOrderDetailImport',
                method: 'post',
                data: fd,
              })
                .then(() => {
                  context.gridModel.onQuery();
                  message.success('上传成功');
                  destroyAllModal();
                });
            })}
            onCancel={destroyAllModal}
            templateUrl={getStaticResourceUrl('pc/ts/egenie-cloud-wms/downloadTemplate/入库单导入模板.xlsx')}
          />
        );
      },
    },
  ];
}
