import { RenderByCondition } from 'egenie-common';
import { ExportModal, ProgrammeComponent } from 'egenie-utils';
import { inject, observer, Provider } from 'mobx-react';
import React from 'react';
import type { VendorItem } from '../../utils';
import { AddAndEditModal } from './addAndEdit/addAndEditModal';
import { Store } from './store';
import { UniqueCodeDetailModal } from './uniqueCodeDetail';
import { CreatePaymentModal } from './createPaymentModal';

@inject('store')
@observer
class ModalWrapper extends React.Component<{ store?: Store; }> {
  render() {
    const {
      showEditModal,
      closeEditModal,
      editModalCallback,
      mainItem,
      showAddModal,
      closeAddModal,
      addModalCallback,
      programme,
    } = this.props.store;
    const vendorIdData = (programme.filterItems.dict.vendorId || []) as VendorItem[];
    const otherStockOrderType = this.props.store.programme.filterItems.dict.otherStockOrderType || [];
    return (
      <>
        <RenderByCondition show={showEditModal}>
          <AddAndEditModal
            callback={editModalCallback}
            mainItem={mainItem}
            onCancel={closeEditModal}
            otherStockOrderTypeData={otherStockOrderType}
            title="编辑其它入库单"
            vendorIdData={vendorIdData}
          />
        </RenderByCondition>
        <RenderByCondition show={showAddModal}>
          <AddAndEditModal
            callback={addModalCallback}
            onCancel={closeAddModal}
            otherStockOrderTypeData={otherStockOrderType}
            title="新建其它入库单"
            vendorIdData={vendorIdData}
          />
        </RenderByCondition>
      </>
    );
  }
}

export default function() {
  const store = React.useMemo(() => new Store(), []);

  return (
    <Provider store={store}>
      <ProgrammeComponent store={store.programme}/>
      <ExportModal store={store.exportStore}/>
      <ModalWrapper/>
      <UniqueCodeDetailModal uniqueCodeDetailModel={store.uniqueCodeDetailModel}/>
      <CreatePaymentModal createPaymentModalModel={store.createPaymentModalModel}/>
    </Provider>
  );
}
