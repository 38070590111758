import { Button } from 'antd';
import { multiple, toFixed } from 'egenie-common';
import type { PaginationData } from 'egenie-utils';
import { TimeStampFormatter, request, ImgFormatter } from 'egenie-utils';
import React from 'react';
import type { Store } from './store';
import type { StockDetailItem } from './types';

export function stockInDetailGrid(context: Store) {
  return {
    filterItems: [
      {
        field: 'skuNo',
        label: 'SKU编码',
        type: 'input',
      },
      {
        field: 'productNo',
        label: '商品编码',
        type: 'input',
      },
      {
        field: 'printStatus',
        label: '打印状态',
        type: 'select',
        options: [
          {
            value: '1',
            label: '已打印',
          },
          {
            value: '0',
            label: '未打印',
          },
        ],
      },
    ],
    tab: {
      name: '入库单详情',
      value: 'detail',
    },
    grid: {
      sumColumns: [
        'number',
        'sum',
      ],
      columns: [
        {
          frozen: true,
          name: '操作',
          key: 'operate',
          width: 100,
          formatter: ({ row }: { row: StockDetailItem; }) => {
            return (
              <Button
                onClick={(event) => {
                  event.stopPropagation();
                  context.uniqueCodeDetailModel.handleOpen(row);
                }}
                type="link"
              >
                唯一码详情
              </Button>
            );
          },
        },
        {
          name: '库区',
          key: 'warehouseAreaName',
          width: 150,
        },
        {
          name: '库位',
          key: 'warehouseBinName',
          width: 150,
        },
        {
          key: 'pic',
          name: '图片',
          width: 50,
          formatter: ({ row }) => <ImgFormatter value={row.pic}/>,
        },
        {
          name: 'SKU编码',
          key: 'skuNo',
          width: 150,
        },
        {
          name: '商品编码',
          key: 'productNo',
          width: 150,
        },
        {
          name: '供应商',
          key: 'vendorNo',
          width: 150,
        },
        {
          name: '数量',
          key: 'number',
          width: 80,
        },
        {
          name: '商品名称',
          key: 'productName',
          width: 150,
        },
        {
          name: '颜色',
          key: 'color',
          width: 100,
        },
        {
          name: '尺码',
          key: 'size',
          width: 100,
        },
        {
          name: '规格',
          key: 'spec',
          width: 100,
        },
        {
          name: '条形码',
          key: 'barCode',
          width: 150,
        },
        {
          name: '成本价',
          key: 'costPrice',
          width: 80,
          formatter: ({ row }: { row: StockDetailItem; }) => (
            <span>
              {toFixed(row.costPrice, 2)}
            </span>
          ),
        },
        {
          name: '金额',
          key: 'sum',
          width: 80,
          formatter: ({ row }: { row: StockDetailItem; }) => (
            <span>
              {toFixed(row.sum, 2)}
            </span>
          ),
        },
        {
          name: '打印状态',
          key: 'printStatus',
          width: 80,
          formatter: ({ row }: { row: StockDetailItem; }) => (
            <span>
              {row.printStatus ? '已打印' : '未打印'}
            </span>
          ),
        },
        {
          name: '打印次数',
          key: 'printCount',
          width: 80,
        },
        {
          key: 'billStatusStr',
          name: '应付单对账',
          width: 100,
        },
        {
          key: 'putAwayNum',
          name: '上架数量',
          width: 80,
        },
        {
          key: 'arrivalTime',
          name: '到货时间',
          width: 150,
          formatter: ({ row }) => <TimeStampFormatter value={row.arrivalTime}/>,
        },
        {
          key: 'billGeneratedNumber',
          name: '应付数量',
          width: 80,
        },
      ].map((info) => ({
        resizable: true,
        sortable: false,
        ...info,
      })),
      setColumnsDisplay: true,
      gridIdForColumnConfig: '/egenie-cloud-wms/stockIn/index/2',
      rows: [],
      pageSize: 50,
      primaryKeyField: 'id',
      sortByLocal: false,
      showCheckBox: true,
    },
    api: {
      onQuery: ({
        data,
        pid,
        cursorRow,
      }) => {
        const {
          cond,
          sidx,
          sord,
          page,
          pageSize,
        } = data;
        return request<PaginationData<StockDetailItem>>({
          url: '/api/cloud/wms/rest/bill/other/detail/page',
          method: 'POST',
          data: {
            ...cond,
            wmsOtherStockOrderId: pid,
            sidx,
            sord,
            page,
            pageSize,
          },
          headers: { warehouseId: cursorRow?.warehouseId || '' },
        })
          .then((info) => ({
            ...info,
            data: {
              ...info.data,
              list: (info?.data?.list || []).map((item) => ({
                ...item,
                sum: multiple(item.number, item.costPrice),
              })),
            },
          }));
      },
    },
  };
}
