import { Button, Input, message } from 'antd';
import { request, toFixed } from 'egenie-common';
import { Permission, TimeStampFormatter } from 'egenie-utils';
import { Observer } from 'mobx-react';
import React from 'react';
import type { Store } from './store';
import type { StockMainItem } from './types';
import { isChooseStyleOwner } from '../../utils';
import moment from 'moment';

export function mainTableColumns(context: Store) {
  return [
    {
      name: '操作',
      key: 'operate',
      width: 150,
      formatter: ({ row }: { row: StockMainItem; }) => {
        return (
          <>
            {
              row.approveState === 2 ? (
                <span>
                  已审核
                </span>
              ) : (
                <Permission permissionId="60006_44">
                  <Button
                    onClick={(event) => {
                      event.stopPropagation();
                      context.openEditModal(row);
                    }}
                    type="link"
                  >
                    编辑
                  </Button>
                </Permission>
              )
            }
            {
              row.approveState === 2 && row.sourceType !== 21 && isChooseStyleOwner(row.ownerId) ? (
                <Permission permissionId="60006_5033">
                  <Button
                    onClick={(event) => {
                      event.stopPropagation();
                      context.createPaymentModalModel.handleOpen(row);
                    }}
                    type="link"
                  >
                    应付改数量
                  </Button>
                </Permission>
              ) : null
            }
          </>
        );
      },
    },
    {
      name: '单据状态',
      key: 'approveStateStr',
      width: 100,
    },
    {
      name: '单据编号',
      key: 'wmsOtherStockOrderNo',
      width: 200,
    },
    {
      name: '仓库',
      key: 'warehouseName',
      width: 150,
    },
    {
      name: '货主',
      key: 'ownerName',
      width: 150,
    },
    {
      name: '供应商',
      key: 'vendorNo',
      width: 150,
    },
    {
      name: '来源单据号',
      key: 'sourceNo',
      width: 200,
    },
    {
      name: '单据类型',
      key: 'otherStockOrderTypeStr',
      width: 150,
    },
    {
      name: '审核人',
      key: 'approvedUserName',
      width: 100,
    },
    {
      name: '采购员',
      key: 'purchaser_user_name',
      width: 100,
    },
    {
      name: '制单日期',
      key: 'otherStockOrderDateStr',
      width: 150,
      formatter: ({ row }) => (
        <span>
          {row.otherStockOrderDateStr ? moment(row.otherStockOrderDateStr).format('YYYY-MM-DD') : ''}
        </span>
      ),
    },
    {
      name: '审核时间',
      key: 'approvedTime',
      width: 150,
      formatter: ({ row }) => <TimeStampFormatter value={row.approvedTime}/>,
    },
    {
      name: '创建时间',
      key: 'createTime',
      width: 150,
      formatter: ({ row }) => <TimeStampFormatter value={row.createTime}/>,
    },
    {
      name: '更新时间',
      key: 'lastUpdateTime',
      width: 150,
      formatter: ({ row }) => <TimeStampFormatter value={row.lastUpdateTime}/>,
    },
    {
      name: '创建人',
      key: 'createUser',
      width: 150,
    },
    {
      name: '备注',
      key: 'note',
      width: 220,
      formatter: ({
        rowIdx,
        row,
      }: { rowIdx: number; row: StockMainItem; }) => {
        return (
          <Observer>
            {
              () => (
                <Input
                  onBlur={() => {
                    request({
                      url: '/api/cloud/wms/rest/bill/other/updateNoteByStockId',
                      method: 'post',
                      data: {
                        otherStockOrderId: row.id,
                        newNote: context.gridModel.gridModel.rows[rowIdx].note,
                      },
                    })
                      .then(() => {
                        message.success({
                          key: '备注修改成功',
                          content: '备注修改成功',
                        });
                      });
                  }}
                  onChange={(event) => context.gridModel.gridModel.rows[rowIdx].note = event.target.value}
                  onClick={(event) => event.stopPropagation()}
                  size="small"
                  value={context.gridModel.gridModel.rows[rowIdx].note}
                />
              )
            }
          </Observer>
        );
      },
    },
    {
      key: 'totalNumber',
      name: '总数量',
      width: 80,
    },
    {
      key: 'totalPrice',
      name: '总金额',
      width: 80,
      formatter: ({ row }: { row: StockMainItem; }) => {
        return (
          <span>
            {toFixed(row.totalPrice, 2)}
          </span>
        );
      },
    },
    {
      key: 'billStatusStr',
      name: '应付单对账',
      width: 100,
    },
    {
      key: 'pledgeStr',
      name: '质押入库',
      width: 80,
    },
  ].map((info) => ({
    resizable: true,
    sortable: false,
    ...info,
  }));
}
