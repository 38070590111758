import type { FilterItemOptions } from 'egenie-utils';
import type { Store } from './store';

export function stockInFilterItems(context: Store): FilterItemOptions[] {
  return [
    {
      type: 'date',
      field: 'date',
      label: '日期类型',
      selectValue: '1',
      startParamsField: 'startTime',
      endParamsField: 'endTime',
      data: [
        {
          value: '3',
          label: '制单日期',
        },
        {
          value: '2',
          label: '审单时间',
        },
        {
          value: '1',
          label: '创建时间',
        },
      ],
    },
    {
      type: 'select',
      field: 'warehouseId',
      label: '仓库',
      allowClear: false,
      onChangeCallback: (warehouseIds: string) => {
        context.getDataAfterWarehouse(warehouseIds);
        context.programme.filterItems.updateFilterItem([
          {
            field: 'ownerId',
            value: undefined,
          },
        ]);
      },
    },
    {
      type: 'select',
      field: 'approveState',
      label: '单据状态',
      mode: 'multiple',
    },
    {
      type: 'select',
      field: 'otherStockOrderType',
      label: '单据类型',
      mode: 'multiple',
    },
    {
      field: 'wmsOtherStockOrderNo',
      type: 'input',
      label: '单据编号',
    },
    {
      field: 'barCode',
      type: 'input',
      label: '条形码',
    },
    {
      field: 'productNo',
      type: 'input',
      label: '商品编码',
    },
    {
      field: 'skuNo',
      type: 'input',
      label: 'SKU编码',
    },
    {
      field: 'sourceNo',
      type: 'input',
      label: '来源单据号',
    },
    {
      field: 'note',
      type: 'input',
      label: '备注',
    },
    {
      type: 'select',
      field: 'creator',
      label: '创建人',
      mode: 'multiple',
    },
    {
      type: 'select',
      field: 'ownerId',
      label: '货主',
      mode: 'multiple',
    },
    {
      type: 'select',
      field: 'vendorId',
      label: '供应商',
      mode: 'multiple',
    },
    {
      type: 'select',
      field: 'printStatus',
      label: '打印状态',
      data: [
        {
          value: '1',
          label: '已打印',
        },
        {
          value: '0',
          label: '未打印',
        },
      ],
    },
    {
      type: 'select',
      field: 'billStatus',
      mode: 'multiple',
      label: '应付单对账',
      data: [
        {
          value: '0',
          label: '未生成',
        },
        {
          value: '1',
          label: '部分生成',
        },
        {
          value: '2',
          label: '已生成',
        },
      ],
    },
    {
      type: 'select',
      field: 'vendorShopId',
      label: '所属供应商',
      mode: 'multiple',
    },
    {
      type: 'select',
      field: 'pledge',
      label: '质押入库',
      data: [
        {
          value: '1',
          label: '是',
        },
        {
          value: '0',
          label: '否',
        },
      ],
    },
  ];
}
